import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { MessageService } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class ToastService {
  private readonly key: string = 'main';

  constructor(
    private messageService: MessageService,
    private translocoService: TranslocoService
  ) {}

  public showSuccess(message: MessageText): void {
    this.showMessage('success', 'Success', message);
  }

  public showWarning(message: MessageText): void {
    this.showMessage('warn', 'Warning', message);
  }

  public showError(message: MessageText): void {
    this.showMessage('error', 'Error', message);
  }

  public showInformation(message: MessageText): void {
    this.showMessage('info', 'Info', message);
  }

  private showMessage(
    severity: string,
    summary: string,
    message: MessageText
  ): void {
    const translatedMessage = this.translocoService.translate(message);
    this.messageService.add({
      key: this.key,
      severity: severity,
      summary: summary,
      detail: translatedMessage,
      life: 4000,
    });
  }
}

export enum MessageText {
  userCreated = 'userCreated',
  userDeleted = 'userDeleted',
  wrongData = 'wrongData',
  updateFeaturesError = 'updateFeaturesError',
  aiCommentForForm = 'aiCommentError',
  voteSaved = 'voteSaved',
  chooseVote = 'chooseVote',
}
